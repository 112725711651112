//
// This SCSS generates the css for the general styles
//
// It is used by all CSS generators (Bootstrap3/4, etc.)
// To keep things simple, it uses the standard variables of
// bootstrap 4 conventions.
//

.sidebar-categories {
	margin-bottom: 16px;
}

.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	pointer-events: auto;
	content: "";
	background-color: rgba(0,0,0,0);
}

.app-blog-top-list {
	.category-header {
		border-bottom: 2px solid #f5f5f5;
		margin-bottom: 16px !important;
		padding-bottom: 10px;

		h3.h2 {
			color: #DB5F23;
		}
	}

	.list-item {
		@media (min-width: 768px) {
			display: flex;
		}
		margin-bottom: 20px;
		padding-bottom: 16px;
		border-bottom: 2px solid #f5f5f5;
		position: relative;

		.col-left {
			flex: 0 0 320px;
			padding: 0 24px 0 0;
			picture {
				margin-bottom: 16px;
			}

			@media (min-width: 768px) {
				flex: 0 0 210px;

				picture {
					margin-bottom: 0;
				}
			}
		}

		.col-right {
			padding: 0 8px 0 0;

			h3 {
				margin-top: 0;
				font-weight: normal;
    		color: #DB5F23;
			}
		}
	}
}

.co-category-list-container,
.sidebar-linklist {
	padding: 0px;
	min-width: 100%;
	margin-bottom: 16px;

	strong {
		display: flex;
		justify-content: space-between;
		padding: 0 8px;
		line-height: 32px;
		height: 32px;
		border-bottom: 1px solid #e6e6e6;
		align-items: center;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding:0;

		li {
			&.category-active,
			&.active {
				background-color: #f5f5f5;
			}
			
			a {
				display: block;
				padding: 0 8px;
				line-height: 32px;
				height: 32px;
				text-decoration: none;
				color: #474747;

				&:hover {
					background-color: #f5f5f5;
					color: #197ABF;
				}
			}
		}
	}
}
