.co-content-text-only {
   h1 {
      margin-bottom: $grid-gutter-width * 1.5;
   }
}
section {
   &.app-blog {
      .category-header {
         margin-bottom: $grid-gutter-width * 1.5;

         h2 {
            margin-top: 0;
            margin-bottom: 0;
         }

         p {
            margin-bottom: 0;
         }
      }

      article {
         margin-bottom: $grid-gutter-width*1.5;

         &.list-item {
            display: flex;
            padding-bottom: $grid-gutter-width*1.5;
            border-bottom: 2px solid #f5f5f5;

            @media (max-width: 992px) {
               flex-direction: column;
            }

            .col-left {
               flex: 0 0 222px;
               padding: 0 8px;

               @media (max-width: 992px) {
                  flex: 0 0 auto;
                  margin-bottom: 16px;
               }
            }

            .col-right {
               padding: 0 8px;
            }

            &:hover {
               picture {
                  img {
                     opacity: 0.8;
                  }
               }
            }
            
            picture {
               display: block;
               transition: background-color .3s ease-in-out;

               img {
                  transition: opacity .3s ease-in-out;
                  backface-visibility: hidden;
                  width: 100%;
               }
            }

            .tags {
               margin-top: 16px;

               .tag {
                  display: inline-block;
                  position: relative;
                  z-index: 1;
                  border: 1px solid #f5f5f5;
                  color: #474747;
                  padding: 0 8px;
                  line-height: 24px;
                  height: 24px;
                  text-decoration: none;
                  margin-right: 8px;

                  &:first-child {
                     margin-left: 0;
                  }
               }
            }

            .content {
               .content-title {
                  margin-top: 0;
               }

               .stretched-link::after {
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  z-index: 1;
                  pointer-events: auto;
                  content: "";
                  background-color: rgba(0,0,0,0);
               }

               time {
                  color: #000;
               }
            }            
         }
      }
   }
}